<template>
  <v-container pa-0 ma-0 fluid>
    <!-- {{screenWidth}} -->
    <v-layout align-center justify-center v-if="loadingSynonym">
          <a-spin class="mt-2" tip="Loading..." />
        </v-layout>
    <v-card class="ml-1 mr-1 pa-1" flat v-else>
      <a-row>
        <a-col :span="24">
          <a-row :gutter="[0, 3]">
            <a-col :span="24">
              <v-toolbar dense style="background-color: #f6f5f5" flat>
                <!-- <v-toolbar-title>  -->
                <v-layout>
                  <v-icon class="mr-1" style="color: #0f3057">
                    mdi-file-document
                  </v-icon>
                  <span style="font-size: 20px; font-weigth: bold"
                    >Multiple
                  </span>
                  <span
                    class="hidden-xs-only"
                    style="font-size: 20px; font-weigth: bold"
                  >
                    Keywords
                  </span>
                  <v-spacer />
                </v-layout>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <a-button
                    v-if="isAdmin()"
                      v-on="on"
                      icon="plus"
                      style="background-color: #0f3057; color: white"
                      @click="
                        isNew = true;
                        editItem([], 0);
                      "
                      >New</a-button
                    >
                  </template>
                  <span> Add new record </span>
                </v-tooltip>
              </v-toolbar>
            </a-col>
            <a-col :span="24" :md="isNew ? 16 : 24">
              <a-input v-model="search"
              addon-before="Search" class="mt-1 mb-1" :style="isNew ?'width:95%':'width:99%'"></a-input>

              <a-table
                :columns="header"
                :data-source="synonymPaginatedData"
                bordered
                :scroll="{ x: 1500, y: screenSize-270 }"
                :pagination="false"
              >
                <span slot="sGroup" slot-scope="sGroup">
                  <a-tag v-for="tag in sGroup" :key="tag" color="blue">
                    {{ tag }}
                  </a-tag>
                </span>
                <a slot="action" slot-scope="text, record" v-if="isAdmin()">
                  <v-layout row wrap>
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-icon
                          v-on="on"
                          @click="
                            isNew = true;
                            editItem(record, 1);
                          "
                          style="color: #0f3057"
                          >mdi-pencil-box
                        </v-icon>
                      </template>
                      <span>Edit Record</span>
                    </v-tooltip>
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-icon
                          v-on="on"
                          @click="
                            deleteKeyword(record);
                          "
                          style="color: #0f3057"
                          >mdi-delete
                        </v-icon>
                      </template>
                      <span>Delete Record</span>
                    </v-tooltip>
                  </v-layout>
                </a>
              </a-table>
            </a-col>
            <a-col v-if="isNew" :md="8">
              <v-toolbar dense flat style="background-color: #f6f5f5">
                <v-icon class="mr-1">mdi-pencil-box-outline</v-icon>
                <span style="font-size: 16px; font-weight: bold">
                  Add New Multiple Keywords
                </span>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <a-button
                      v-on="on"
                      icon="save"
                      style="background-color: #455a64; color: white"
                      @click="SaveKeywords()"
                    >
                    </a-button>
                  </template>
                  <span> Save records </span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <a-button
                      v-on="on"
                      icon="close"
                      style="background-color: #455a64; color: white"
                      @click="isNew = false"
                    >
                    </a-button>
                  </template>
                  <span>Close </span>
                </v-tooltip>
              </v-toolbar>
              <v-row no-gutters dense>
                <v-col cols="12">
                  <a-input
                class="ma-1"
                addon-before="Group Name"
                style="width: 99%"
                v-model="editgName"
              />
                </v-col>
              <v-col cols="3">
              <!-- <span style="font-size: 14px; font-weight: bold" class="ml-3"
                >Subgroup:</span
              > -->
              <span class="ml-3 font-weight-bold subtitle-2"
                >Subgroup:</span
              >
              </v-col>
              <v-spacer/>
               
                <v-col cols="9">
              <!-- :style="SubWidth" -->
                <a-select
                mode="multiple"
                v-model="editsGroup"
                style="width:100%"
              >
                <a-select-option
                  v-for="(item, i) in optSubgroup"
                  :key="i"
                  :value="item"
                >
                  {{ item }}
                </a-select-option>
              </a-select>
              </v-col>
              <!-- <v-layout class="ma-1">
                <v-spacer />
              </v-layout> -->
            </v-row>
              <v-layout class="ma-1">
                <table align-center justify-center>
                  <!-- :id="ListResponsive()" -->
                  <tbody :style="tbodyStyle" id="content">
                    <!-- :id="TbodyResponsive()" -->
                    <tr style="background-color: #0f3057; color: #fafafa">
                      <td style="border-color: #0f3057"></td>
                      <td style="text-align: center; border-color: #0f3057">
                        <span> Keywords </span>
                      </td>
                      <td style="text-align: center"></td>
                    </tr>
                    <tr v-for="(item, index) in items" :key="index">
                      <td>{{ index + 1 }}</td>

                      <td>
                        <input
                          @keyup.enter="AddItem()"
                          type="text"
                          v-model="items[index]"
                        />
                      </td>
                      <td class="text-xs-center">
                        <v-icon @click="removeItem(index)"
                          >mdi-close-box-outline</v-icon
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
                <v-tooltip right>
                  <template v-slot:activator="{ on }">
                    <a-button
                      v-on="on"
                      icon="plus"
                      style="background-color: #455a64; color: white"
                      @click="AddItem()"
                    >
                    </a-button>
                  </template>
                  <span>Add new keyword </span>
                </v-tooltip>
              </v-layout>
            </a-col>
          </a-row>
        </a-col>
      </a-row>
    </v-card>
    <a-row>
        <a-col :span="24" :md="isNew ? 16 : 24">
    <v-pagination
          v-model="synonymPageNo"
          :length="synonymPageCount"
          :total-visible="5"
          dark
          prev-icon="mdi-menu-left"
          next-icon="mdi-menu-right"
          v-if="!loadingSynonym"
        ></v-pagination>  
        </a-col>
 </a-row> 
 </v-container>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      synonymPageNo: 1,
      rowSynonymCount: 30,
      loadingSynonym: true,
      search:'',
      header: [],
      isNew: false,
      isDialog: false,
      editgName: "",
      editsGroup: [],
      items: [],
      optSubgroup: [],
      synonymData: [],
      isUpdate: false,
      editOldgGName: "",
    };
  },
  created() {
    // this.synonymData = [{
    //   gName: '手摺り',
    //   keyword:[
    //     'てすり',
    //     '手すり'
    //   ],
    //   sGroup:['吹抜け','フキサゲ']
    // }

    // ]
    this.loadMultiData();
  },
  computed: {
      synonymPageCount() {
      let l = this.filteredKeywords.length,
        s = this.rowSynonymCount;
      return Math.ceil(l / s);
    },
     synonymPaginatedData() {
      const start = (this.synonymPageNo - 1) * this.rowSynonymCount,
        end = start + this.rowSynonymCount;
      return this.filteredKeywords.slice(start, end);
    },
    filteredKeywords(){
      if(this.search){
        return this.synonymData.filter((data) => {
         let search = new RegExp(this.search.toUpperCase(), "g");
          return JSON.stringify(Object.values(data)).toUpperCase().match(search);
        });
      }
      else return this.synonymData
    },
    ListResponsive() {
      let screen = eval(`${window.screen.height} * ${window.devicePixelRatio}`);
      // console.log(screen)
      if (screen == "768") {
        return "responsive768";
      } else if (screen == "1024") {
        return "responsive1024";
      } else if (screen == "1080") {
        return "responsive1080";
      } else if (screen >= "1200") {
        return "responsive1200";
      } else {
        return "responsive";
      }
    },
    tableStyle() {
      //  height:${this.screenSize - 350}
      // console.log(
      //   `width: 100%; overflow:auto; height:${this.screenSize - 300}`
      // );
      return ` overflow:auto; height:${this.screenSize - 200}`;
    },
    tbodyStyle() {
      return ` height:${this.screenSize - 300}px;
                display: inline-block;
                overflow: auto;`;
    },
    screenSize() {
      return this.$vuetify.breakpoint.height;
    },
    // screenWidth(){
    //   return this.$vuetify.breakpoint.width;
    // },
    SubWidth(){
      if(this.screenWidth == '1280'){
        return `width:310px`

      }else{
        return `width:335px`

      }
    }
  },
  methods: {
    deleteKeyword(val) {
      //  axios.defaults.headers.common["x-api-key"] =
      // "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";
      // console.log(val)
        Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: `Yes, Delete !`,
      }).then((result) => {
        if(result.value){
         let url = `${this.api}delete/multidata?group=${val.gName}`;
      axios.get(url).then(() => {
        // console.log(res.data);
         Swal.fire({
            icon: "success",
            title: "Deleted!",
            showConfirmButton: false,
            timer: 1500,
          });
          this.loadMultiData()
      });
      }
      });
     
    },
    removeItem(i) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: `Yes, Delete !`,
      }).then((result) => {
        if (result.value) {
          this.items.splice(i, 1);
          // let url = `${this.api}update/obsolete?ID=${val._id}`;
          // axios.post(url).then(() => {});

          Swal.fire({
            icon: "success",
            title: "Deleted!",
            showConfirmButton: false,
            timer: 1500,
          });
          // this.loadSynonymData();
        }
      });
    },
    SaveKeywords() {
      axios.defaults.headers.common["x-api-key"] =
        "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";
      let toAdd = {};
      toAdd.keywords = this.items;
      toAdd.sGroup = this.editsGroup;
      toAdd.oldGname = this.editOldgGName;
      if (!this.editgName && !this.items) {
        Swal.fire({
          icon: "error",
          title:
            "Empty group name and keywords! Please fill up the records before saving.",
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        if (this.isUpdate) {
          // console.log(toAdd)
          let url = `${this.api}update/multidata?group=${this.editgName}`;
          axios.post(url, toAdd).then(() => {
            // console.log('Save:',res.data)
            Swal.fire({
              icon: "success",
              title: "Successfully Updated!",
              showConfirmButton: false,
              timer: 1500,
            });
            this.isNew = false;
            this.loadMultiData();
          });
        } else {
          // console.log('New')
          let checkURL = `${this.api}check/multi?group=${this.editgName}`
          axios.get(checkURL).then(res=>{
            // console.log(res.data)
            if(res.data== false){
              let url2 = `${this.api}add/multidata?group=${this.editgName}`;
          axios.post(url2, toAdd).then(() => {
            // console.log("Save:", res.data);
            Swal.fire({
              icon: "success",
              title: "Successfully Added!",
              showConfirmButton: false,
              timer: 1500,
            });
            this.isNew = false;
            this.loadMultiData();
          });
            }
            else {
               Swal.fire({
              icon: "error",
              title: "Group Name already exists!",
              
            });
            }
          })
          
        }
      }
    },
    async AddItem() {
      // if (this.enableAdd) {
      if (this.items.length == 0) {
        // console.log("aaaa");
        await this.items.push("");
        await this.scrollDown();
      } else {
        if (!this.items[this.items.length - 1]) {
          // alert('Empty keyword! Please add valid keyword before adding new one.')
          Swal.fire({
            icon: "error",
            title:
              "Empty keyword! Please add valid keyword before adding new one.",
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          await this.items.push("");
          await this.scrollDown();
        }
      }
      // }
    },
    TbodyResponsive() {
      let screen = eval(`${window.screen.height} * ${window.devicePixelRatio}`);
      if (screen == "768") {
        return "tbody768";
      } else if (screen == "1024") {
        return "tbody1024";
      } else if (screen == "1080") {
        return "tbody1080";
      } else if (screen >= "1200") {
        return "tbody1200";
      } else {
        return "tbody";
      }
    },
    scrollDown() {
      let elem = document.getElementById(`content`);
      // console.log(elem)
      elem.scrollTop = 999999;
    },
    editItem(val, opt) {
      let toEdit = Object.assign({}, val);
      this.editOldgGName = toEdit.gName;
      if (opt == 1) {
        this.isUpdate = true;
        this.editgName = toEdit.gName;
        if (toEdit.sGroup) {
          this.editsGroup = toEdit.sGroup;
        }
        this.items = toEdit.keyword;
      } else {
        this.isUpdate = false;

        this.editgName = "";
        this.editsGroup = [];
        this.items = [];
      }
      // console.log(opt);
      // console.log(val);
    },
    loadMultiData() {
      axios.defaults.headers.common["x-api-key"] =
        "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";
      let url = `${this.api}get/multidata`;
      axios.get(url).then((res) => {
        let test = Object.keys(res.data.data);
        this.optSubgroup = test;
        let fData = test.map((rec, index) => {
          let data = {};
          data.key = index;
          data.gName = rec;
          data.keyword = [];
          res.data.data[rec].map((r) => {
            if (typeof r == "string") {
              data.keyword.push(r);
            } else {
              data.sGroup = r.sGroup;
            }
          });

          return data;
        });
        // console.log(fData);
        this.synonymData = fData;
        this.header = [
          {
            title: "Group Name",
            dataIndex: "gName",
            key: "gName",
            width: 180,
            fixed: "left",
          },
          {
            title: "Sub Group",
            dataIndex: "sGroup",
            key: "sGroup",
            width: 250,
            fixed: "left",
            scopedSlots: { customRender: "sGroup" },
          },
        ];
        var longest = 0;
        var longestKeyword = [];
        fData.forEach(function (key) {
          if (key.keyword) {
            if (key.keyword.length > longest) {
              longestKeyword = [key];
              longest = key.keyword.length;
            } else if (key.keyword.length == longest) {
              longestKeyword.push(key);
            }
          }
        });
        // console.log("length");
        for (let i = 0; i < longestKeyword[0].keyword.length; i++) {
          // let toPush = {}
          let toPush = {
            title: `Keyword ${i + 1}`,
            dataIndex: `keyword[${i}]`,
            key: `keyword[${i}]`,
          };

          this.header.push(toPush);
        }
        let action = {
          title: "",
          dataIndex: "action",
          key: "action",
          fixed: "right",
          scopedSlots: { customRender: "action" },
          width: 60,
        };
        this.header.push(action);
        // console.log(this.header);
        this.loadingSynonym = false
      });
    },
  },
};
</script>

<style scoped>
table {
  border-collapse: collapse;
  border-style: solid;
}

table td {
  border-left: 1px solid #000;
  border-right: 1px solid #000;
  padding: 15px;
  width: auto;
}

table tr {
  border-collapse: collapse;
  border-style: solid;
}
#tbody768 {
  height: 439px;
  display: inline-block;
  width: 100%;
  overflow: auto;
}

#tbody1024 {
  height: 689px;
  display: inline-block;
  width: 100%;
  overflow: auto;
}

#tbody1080 {
  height: 739px;
  display: inline-block;
  width: 100%;
  overflow: auto;
}

#tbody1200 {
  height: 780px;
  display: inline-block;
  width: 100%;
  overflow: auto;
}

#tbody {
  height: 430px;
  display: inline-block;
  width: 100%;
  overflow: auto;
}
#responsive {
  max-height: 95vh;
  width: 100%;
  overflow: auto;
}
#responsive768 {
  max-height: 71vh;
  width: 100%;
  overflow: auto;
}
#responsive1024 {
  max-height: 79vh;
  width: 100%;
  overflow: auto;
}
#responsive1080 {
  max-height: 85vh;
  width: 100%;
  overflow: auto;
}
#responsive1200 {
  max-height: 90vh;
  width: 100%;
  overflow: auto;
}
</style>